$break-small: 575px;
$break-tablet: 769px;
$break-large: 1025px;
$break-extralarge: 1200px;

.backgroundNew {
    -webkit-filter: grayscale(40%);
    height: 780px;
    margin-top: -6px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    padding: 0 32px;
    @media screen and (max-width: $break-large) {
      width: 100vw;
    }
    @media screen and (max-width: $break-tablet) {
      height: 820px;
    }
    @media screen and (max-width: $break-small) {
      height: 700px;
      padding: 0;
    }
}

.backgroundNew2 {
  -webkit-filter: grayscale(40%);
  height: 780px;
  margin-top: -6px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  padding: 0 32px;
  @media screen and (max-width: $break-extralarge) {
    width: 100vw;
    height: 1120px;
  }
  @media screen and (max-width: $break-small) {
    height: 1320px;
    padding: 0 24px;
  }
}

.tripSendText {
    font-family: 'Catamaran', sans-serif;
    font-size: 56px;
    line-height: 64px;
    color: #404348;
    font-weight: 800;
    display: inline-block;  
    // text-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    /*@media screen and (max-width: $break-large) {
      display: block;
      font-size: 30px;
      padding: 0px 64px 0px 64px;
    }*/
    @media screen and (max-width: $break-small) {
      font-size: 28px;
      line-height: 36px;
      padding: 0 24px;
  }
}
.invalid-feedback {
  margin-bottom: 5px;
  font-size: 12px;
  margin-top: -5px; 
}

.subSendTripText {
  font-family: lato;
  font-size: 16px;
  line-height: 24px;
  color: #404348;
  font-weight: 500;
  @media screen and (max-width: $break-large) {
    font-size: 15px;
    position: relative;
    top: 2px;
  }
  @media screen and (max-width: $break-small) {
    font-size: 15px;
    position: relative;
    top: 2px;
    width: 80%;
  }

}

.headerDiv {
  display: inline-block;
  padding: 24px 32px;
  width: 100vw;
  text-align: left;
  border-bottom: 1px solid #e9e9eb;
  @media screen and (max-width: $break-small) {
      padding: 24px;
  }
}

.bottomDiv {
  display: inline-block;
  padding: 24px 32px 24px;
  width: 100vw;
  text-align: left;
  border-top: 1px solid #e9e9eb;
  @media screen and (max-width: $break-small) {
      border-top: none;
      padding: 24px;
      margin-top: -10px;
  }
}

.formArea {
    padding: 48px 48px 24px;
    width: 40%;
    height: 50%;
    text-align: center;
    background-color: white; 
    margin: auto;
    position: relative;

    @media screen and (max-width: $break-large) {
      padding: 20px;
      margin: 0;
      height: 50vh;
      width: 100vw;
      top: 20px !important
  }
    @media screen and (max-width: $break-large) {
    padding: 20px 32px;
    margin: 0;
    height: 50vh;
    width: 100vw;
    top: 2px !important
}
}

.formAreaRight {
    max-width: 396px;
    margin: auto;
    @media screen and (max-width: $break-small) {
      max-width: 396px;
    }
}

.textDivSend {
  position: relative;
  padding-top: 72px;
  text-align: center;

  @media screen and (max-width: $break-large) {
    padding-top: 72px;
 }
 @media screen and (max-width: $break-small) {
     padding-top: 32px;
  }

}

/*.inputGroup1 {
  width: 50%;
  display: flex;
  text-align: right;
}

.inputGroup2 {
  width: 50%;
  display: flex;
}*/


.inputGroup {
    background-color: #fff;
    display: inline-block;
    text-align: center;

    label {
      padding: 4px 0 4px 32px;
      margin: 0 8px;
      width: 100%;
      display: block;
      text-align: left;
      color: #404348;
      font-size: 15px;
      font-family: lato;  
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
      
      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        // background-color: #5562eb;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 24px;
        height: 24px;
        content: '';
        border: 1px solid #D1D7DC;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: -1px 0px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
    //   color: #fff;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        background-color: #17ac61;
        border-color: #17ac61;
      }
    }

    input {
      width: 24px;
      height: 24px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }

.form {
  padding: 0 16px;
  max-width: 550px;
  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.radio-buttons {
    display: inline-block;
    margin: auto;
    padding: 16px 0 10px;
    @media screen and (max-width: $break-large) {
      display: inline-block;
      margin: auto;
    }
    @media screen and (max-width: $break-large) {
      display: inline-block;
      margin: auto;
    }
}

.whoText {
    font-family: catamaran; 
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 20px;
    color: #404348;
    @media screen and (max-width: $break-large) {
      position: relative;
      font-size: 18px;
      top: 8px; 
  }
}

.telephoneInput {
    size: 10px;
    width: 100%;
}

.intl-tel-input {
    width: 100%;
    font-size: 20px;
}
input {
    width: 100%;
    padding: 5px;
}
.btn-success {
    margin-top: 20px;
}

.textBlock {
    text-align: center;
    margin: 0 auto;
}

.successText {
    font-family: catamaran;
    font-size: 44px;
    line-height: 56px;
    color: white;
    font-weight: 700;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.subtitleSuccess {
   font-family: lato;
   font-size: 16px;
   line-height: 0px;
    color: white;
    font-weight: 400;
   text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.line {
  display: none;
}

.form-control {
  display: block;
  width: 100%;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c9c9cb;
  transition: border-color ease-in-out .15s;
  padding: 28px 16px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  background-clip: padding-box;
  font-weight: 500;
  font-family: lato;
  margin-bottom: 12px;
  color: #404348 !important;
}

.form-control:hover {
  border: 1px solid #17AC61;
}

.form-control:focus {
  border-color: #17AC61;
  box-shadow: inset 0 0px 0px #17AC61, 0 0 0px #17AC61;
}

.logoSuccess {
  position: fixed;
  margin-left: 24px;
  margin-top: 24px;
  content: url(../../../Icons/Tripsy_black.svg);
  color: black;
  fill: black;
  width: 12vh;
  @media screen and (max-width: $break-small) {
      position: absolute;
      margin-left: 24px;
      margin-top: 16px;
      width: 10vh;
  }
}

.helpIconSuccess {
  position: relative;
  z-index: 1;
  font-size: 16px;
  color: black;
  float: right;
  margin-right: 24px;
  margin-top: 24px;
  @media screen and (max-width: $break-small) {
      margin-right: 24px;
      margin-top: 16px;
  }
}

.helpIconSuccess:hover {
  cursor: pointer;
}

.helpwordSuccess {
  margin-left: 6px;
  vertical-align: center;
  font-size: 16px;
  color: black;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.allow-dropdown input[type=text] {
  border-radius: 4px;
  border: 1px solid #c9c9cb;
  padding: 16px 8px 16px 64px !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: lato;
  color: #404348;
  
}

.intl-tel-input.allow-dropdown .selected-flag {
  width: 56px !important;
  padding: 0 16px;
}

.iti-mobile .intl-tel-input.iti-container{
  @media screen and (max-width: $break-small) {
    top: 575px !important;
    left: 20px !important;
    height: 400px !important;
    width: 90%;
    position: absolute !important;
  }
}

button.btn.btn-success.btn-lg {
  width: 100% !important;
  padding: 16px 0;
  font-size: 16px !important;
  font-weight: 600;
  font-family: lato;
  line-height: 24px;
}

.gratisvrijblijvend {
  padding-top: 16px;
  display: inline-block;
  color: #404348;
  font-size: 15px;
  font-family: lato;
  line-height: 18px;
}

.bottomDiv {
  @media screen and (max-width: $break-small) {
      border-top: 1px solid #e9e9eb !important;
  }
}

.countryNames {
  padding: 24px 0;
}

.continent {
  width: 16,666667%;
  display: inline-block;
  text-align: left;
  padding: 24px 16px;
  margin: 0 32px;
  vertical-align: top;
  @media screen and (max-width: $break-extralarge) {
    width: 25%;
  }
  @media screen and (max-width: $break-small) {
    width: 50%;
    margin: 0;
  }
}

.continentTitle {
  font-family: catamaran;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 0 8px;
}

.countryLink {
  padding: 4px 0;
  font-family: lato;
  font-weight: 500;
  font-size: 16px;
  color: #009249 !important;
}

.helpWidgetEmail {
  position: absolute;
  margin-left: 4px !important;
  margin-top: -50px !important;
  color: #e9e9eb;
}

.helpWidgetEmail:hover {
  color: #404348;
}

.helpWidgetPhone {
  position: absolute;
  margin-left: 4px !important;
  margin-top: 16px !important;
  color: #e9e9eb;
}

.helpWidgetPhone:hover {
  color: #404348;
}

.tooltip-inner {
  font-size: 12px;
  font-family: lato;
  color: #fff;
  text-align: left;
  padding: 8px;
}

.Toastify__toast-body {
  font-family: lato;
  font-size: 15px;
  font-weight: 600;
}


@media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {

    .helpWidgetEmail {
      position: absolute;
      margin-left: 405px;
      margin-top: -108px;
      color: #e9e9eb;

      @media screen and (max-width: $break-small) {
        margin-left: 260px;
      }
    }

    .helpWidgetPhone {
      position: absolute;
      margin-left: 405px;
      margin-top: -40px;
      color: #e9e9eb;
      @media screen and (max-width: $break-small) {
        margin-left: 260px;
      }
    }

    
}
