$break-small: 575px;
$break-large: 1025px;

.speedIcon {
    height: 40px;
    margin: auto; 
    fill: none;
    @media screen and (max-width: $break-small) {
        height: 34px;
        margin: auto; 
    }
}

.card:hover {
    cursor: pointer;
    .speedIcon {
        margin: auto; 
        filter: invert(36%) sepia(49%) saturate(7105%) hue-rotate(141deg) brightness(94%) contrast(102%);
    }
}