$break-small: 575px;
$break-large: 1025px;

.pickerIcon {
    height: 40px;
    margin: auto; 
    fill: none;
    @media screen and (max-width: $break-small) {
        height: 34px;
        margin: auto; 
    }
}

.card:hover {
    cursor: pointer;
    .pickerIcon {
        margin: auto;
        filter: invert(34%) sepia(80%) saturate(3057%) hue-rotate(137deg) brightness(88%) contrast(107%);
    }
}