$break-small: 575px;
$break-tablet: 769px;
$break-large: 1025px;

.topDiv {
    display: inline-block; 
    height: 64vh;
    width: 100vw;
    margin-top: -6px;
    margin-bottom: 280px;
    -webkit-filter: grayscale(30%);
    // background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.35), rgba(0,0,0,0.01))/*,url("../../images/Phuket_thailand_mood.jpg")*/ !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @media screen and (max-width: $break-large) {
        height: 64vh;
        margin-bottom: 500px;
    }
    @media screen and (max-width: $break-small) {
        height: 30vh;
        margin-bottom: 485px;
        // background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5), rgba(0,0,0,0.5))/*,url("../../images/Phuket_thailand_mood.jpg")*/ !important;
    }
}

.logo {
    float: left;
    margin: 0;
    content: url(../../Icons/tripsy-aanvraagform.svg);
    color: white;
    width: 9,6vh;
    @media screen and (max-width: $break-small) {
        position: absolute;
        height: 32px;
    }
}


.textDiv {
    padding-top: 11vh;
    text-align: center;
    @media screen and (max-width: $break-large) {
       padding-top: 11vh;
    }
    @media screen and (max-width: $break-small) {
        padding-top: 9vh;
     }

}

.cardBox {
    position: relative;
    // padding-top: 3vh;
    text-align: left;
    @media screen and (max-width: $break-large) {
        padding-top: 3vh;
    }
    @media screen and (max-width: $break-small) {
        padding-top: 5vh;
    }
}

.headerDiv {
    display: inline-block;
    padding: 18px 32px;
    width: 100vw;
    text-align: left;
    min-height: 70px;
    @media screen and (max-width: $break-small) {
        padding: 18px 24px;
    }
}

.bottomDiv {
    display: inline-block;
    padding: 24px 32px 24px;
    width: 100vw;
    text-align: left;
    border-top: 1px solid #e9e9eb;
    @media screen and (max-width: $break-small) {
        border-top: none;
        padding: 24px;
        margin-top: -10px;
    }
}

.progress {
    position: relative;
    top: 43px;
    width: 950px;
    vertical-align: middle;
    margin: auto;
    z-index: 10;
    @media screen and (max-width: $break-large) {
        width: 640px;
        top: 63px !important;
     }
    @media screen and (max-width: $break-tablet) {
        width: 80%;
        top: 63px;
    }
    @media screen and (max-width: $break-small) {
        width: 100%;
        top: 28px !important;
        border-radius: 0px;
    }

}

.tripText {
    font-family: 'Catamaran', sans-serif;
    font-size: 40px;
    line-height: 48px;
    color: #272727;
    font-weight: 800;
    // text-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    /*@media screen and (max-width: $break-large) {
        display: block;
        font-size: 30px;
        padding: 0px 64px 0px 64px;
    }*/
    @media screen and (max-width: $break-small) {
        font-size: 24px;
        line-height: 32px;
    }
}

.subTripText {
    font-family: lato;
    font-size: 16px;
    line-height: 0px;
    color: #272727;
    font-weight: 500;
    // text-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    padding-top: 24px !important;
    @media screen and (max-width: $break-large) {
        font-size: 15px;
        position: relative;
        top: 0px;
    }
    @media screen and (max-width: $break-small) {
        font-size: 15px;
        position: relative;
        top: 3px;
        width: 80%;
    }
}

.garantyTitle {
    font-size: 40px;
    font-weight: 400;
    @media screen and (max-width: $break-large) {
        font-size: 35px;
    }
}

.garantyText {
    display: inline-block;
    font-size: 15px;
    width: 50vw;
    font-weight: 200;
    @media screen and (max-width: $break-large) {
        width: 80%;
    }
}

.ggtoLogo {
    padding-top: 10px;
    width: 4%;
    height: auto;
    @media screen and (max-width: $break-large) {
        width: 20%;
    }
}

.line {
    width: 100vw;
    height: 80px;
    border-bottom: 1px solid black;
    position: absolute;
    opacity: 0.2;
    @media screen and (max-width: $break-large) {
        height: 25px;
    }
}

.links1 {
    display: table-cell !important;
    vertical-align: middle !important;
    font-family: lato;
    font-size: 14px;
    width: 50vw;
    // color: #404348 !important;
    text-align: left;
    @media screen and (max-width: $break-large) {
        margin: 10px 50px 20px 50px;
    }
    @media screen and (max-width: $break-small) {
        margin: 0px;
        display: inline-block !important;
        vertical-align: top !important;
        width: 100%;
        text-align: center;
    }
}

.links2 {
    display: table-cell !important;
    vertical-align: middle !important;
    font-family: lato;
    font-size: 14px;
    width: 50vw;
    color: #404348;
    text-align: right;
    @media screen and (max-width: $break-large) {
        margin: 10px 50px 20px 50px;
    }
    @media screen and (max-width: $break-small) {
        margin: 8px 0;
        display: inline-block !important;
        vertical-align: top !important;
        width: 100%;
        text-align: center;
    }
}

/* .helpIcon {
    font-size: 16px;
    color: white;
    float: right;
    margin-right: 0px;
    margin-top: 0px;
    @media screen and (max-width: $break-small) {
        margin-right: 0px;
        margin-top: 0px;
    }
}*/

.helpword:hover {
    cursor: pointer;
}

.helpword {
    margin-top: 5px;
    float: right;
    vertical-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: 'Catamaran', sans-serif;
    // text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    color: #404348;
    /* @media screen and (max-width: $break-small) {
        line-height: 24px;
    }*/
}

.onsGarantiefonds {
    margin: 15px 0 6px;
    font-family: lato;
    font-weight: 500;
    font-size: 15px;
    color: #CDCDCD;
    z-index: 20;
}

.ggtogrey {
    height: 24px;
    border-radius: 4px;
}


.garranty {
    width: 100%;
    position: relative;
    align-self: center;
    z-index: 20;    

}

.garrantyDiv {
    position: relative;
    top: 338px;
    z-index: 20;  
    @media screen and (max-width: $break-large) {
        top: 580px;
    }
    @media screen and (max-width: $break-tablet) {
        top: 569px;
    }
    @media screen and (max-width: $break-small) {
        top: 400px;
    }
}  
.linksRight {
    color: #404348;
    font-weight: 500;
    @media screen and (max-width: $break-small) {
        color: #B4B8BD;   
    }
}

.linksLeft {
    margin: 0 0 0 8px;
    color: #404348;
    font-weight: 500;
}

.linksRight:hover {
    color: #009249;
}

a:hover {
    text-decoration: none !important;
}