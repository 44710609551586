$break-small: 575px;
$break-tablet: 769px;
$break-large: 1025px;

.formBoxWithButton {
    margin: auto;
    width: 1030px;
    background-color: white;
    // border: 1px solid #eaeaea;
    padding: 40px 40px 28px;
    // margin-bottom: 20px;
    border-radius: 4px;
    position: relative;
    transition: all 200ms linear 0s;
    -moz-box-shadow: 0px 3px 7px rgba(44,62,71,0.2);
	-webkit-box-shadow: 0px 3px 7px rgba(44,62,71,0.2);
    box-shadow: 0px 3px 7px rgba(44,62,71,0.2);
    overflow: hidden;
    @media screen and (max-width: $break-large) {
        width: 768px;
    }
    @media screen and (max-width: $break-tablet) {
        width: 91%;
        padding: 40px 40px 28px;
        height: auto;
    }
    @media screen and (max-width: $break-small) {
        width: 100%;
        padding: 0px 24px 90px;
        height: auto;
        border-radius: 0px;
        -moz-box-shadow: 0px 3px 7px rgba(44,62,71,0.0);
        -webkit-box-shadow: 0px 3px 7px rgba(44,62,71,0.0);
        box-shadow: 0px 3px 7px rgba(44,62,71,0.0);
        background-color: #f6f7f8;
    }
}

/*.formBox {
    margin: auto;
    width: 1030px;
    background-color: white;
    padding: 40px 40px;
    border-radius: 4px;
    position: relative;
    transition: all 200ms linear 0s;
    -moz-box-shadow: 0px 3px 7px rgba(44,62,71,0.2);
	-webkit-box-shadow: 0px 3px 7px rgba(44,62,71,0.2);
    box-shadow: 0px 3px 7px rgba(44,62,71,0.2);
    overflow: hidden;
    @media screen and (max-width: $break-large) {
        width: 768px;
    }
    @media screen and (max-width: $break-tablet) {
        width: 91%;
        padding: 40px 40px;
        height: auto;
    }
    @media screen and (max-width: $break-small) {
        width: 100%;
        padding: 0px 24px 140px;
        height: auto;
        border-radius: 0px;
        -moz-box-shadow: 0px 3px 7px rgba(44,62,71,0.0);
        -webkit-box-shadow: 0px 3px 7px rgba(44,62,71,0.0);
        box-shadow: 0px 3px 7px rgba(44,62,71,0.0);
        background-color: #f6f7f8;
    }
}*/

.addSpan {
    margin: auto !important;
    min-width: 104px;
}

.background {
    margin-top: 30px;
    height: 0vh;
    
}

.counter {
    font-size: 24px;
    font-weight: 600;
    font-family: lato;
    margin: auto;
    padding: 20px 10px;
    vertical-align: bottom;
    @media screen and (max-width: $break-large) {
        padding: 20px 8px;
        font-size: 18px;
    }
}

.add-button {
    padding: 7px !important;
    background-color: white !important;
    border: 1px solid #eaeaea !important;
    border-radius: 4px;
    color: #009249 !important;
}

.title {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
    size: 12px;
}

.stepButton {
    margin-top: 24px !important;
    padding: 15px 25px 15px 25px !important; 
    float: right;
    position: relative;
    z-index: 25;
    background-color: #17AC61;
}

.stepButton:hover {
    box-shadow: 0 4px 12px 0 rgba(44, 62, 91, 0.3);
    transform: scale(1.01);
    background-color: #009249;
}