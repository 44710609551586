$break-small: 575px;
$break-tablet: 769px;
$break-large: 1025px;

.formBox {
    margin: auto;
    width: 1030px;
    background-color: white;
    padding: 40px 40px 98px;
    border-radius: 4px;
    position: relative;
    transition: all 200ms linear 0s;
    //-moz-box-shadow: 0px 3px 7px rgba(44,62,71,0.2);
	//-webkit-box-shadow: 0px 3px 7px rgba(44,62,71,0.2);
    //box-shadow: 0px 3px 7px rgba(44,62,71,0.2);
    overflow: hidden;
    @media screen and (max-width: $break-large) {
        width: 768px;
    }
    @media screen and (max-width: $break-tablet) {
        width: 91%;
        padding: 40px 40px 98px;
        height: auto;
    }
    @media screen and (max-width: $break-small) {
        width: 100%;
        padding: 0px 24px 100px;
        height: auto;
        border-radius: 0px;
        //-moz-box-shadow: 0px 3px 7px rgba(44,62,71,0.0);
        //-webkit-box-shadow: 0px 3px 7px rgba(44,62,71,0.0);
        //box-shadow: 0px 3px 7px rgba(44,62,71,0.0);
        background-color: #f6f7f8;
    }
}

.background {
    margin-top: 20px !important;
    height: 0vh;
}

.svgIcon {
    height: 38px;
    margin: auto; 
    fill: none;
    @media screen and (max-width: $break-small) {
        height: 34px;
        margin: auto; 
    }
}

.title {
    padding-top: 32px !important;
    padding-bottom: 12px !important;
    padding-left: 2px !important;
    font-family: catamaran;
    font-size: 18px;
    line-height: 28px;
    font-weight: 800 !important;
    color: #404348;
    margin-bottom: 0px !important;
}

.cardFooter {
    text-align: center;
    background-color: white;
    color: #404348;
    font-size: 15px;
    font-weight: 600 !important;
    line-height: 24px;
    font-family: lato;
    border-top: 1px solid #f2f2f2;
    border-radius: 0px 0px 4px 4px !important;
    padding: 12px 8px 13px;
    @media screen and (max-width: $break-small) {
        padding: 8px 6px 9px;
        font-size: 14px;
        font-weight: 600 !important;
    }
}

 .card-deck {
     margin: -4px !important;
     -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
     @media screen and (max-width: $break-large) {
        display: grid;
        grid-gap: .5rem;
        grid-template-columns: 1fr 1fr;
    }
}

.card:hover {
    cursor: pointer;
    box-shadow: 0px 2px 18px rgba(23,33,52,0.2);
    -webkit-transition: box-shadow 0.2s ease-in;
    -moz-transition: box-shadow 0.2s ease-in;
    -o-transition: box-shadow 0.2s ease-in;
    transition: box-shadow 0.2s ease-in;
    .svgIcon {
        // padding: 40px 0px 40px 0px;
        margin: auto;
        filter: invert(34%) sepia(80%) saturate(3057%) hue-rotate(137deg) brightness(88%) contrast(107%);
    }
}

.card {
    margin: 8px !important;
    border-radius: 4px;
    border-width: 0px;

    min-height: 210px;
    max-height: 220px;

    box-shadow: 0px 1px 4px rgba(23,33,52,0.3);
    @media screen and (max-width: $break-small) {
        min-height: 150px;
        max-height: 150px;   
        margin: 4px !important;     
    }
}

.card-body {
    display: flex;
    margin: auto !important;
    align-content: center !important;
    @media screen and (max-width: $break-large) {
    }
}

.progress-bar {
    height: 8px;
    @media screen and (max-width: $break-large) {
        top: 10px;
    }
}

.progress {
    height: 8px;
    @media screen and (max-width: $break-large) {
        top: 10px;
    }
}

.cardPosition {    
    @media screen and (max-width: $break-large) {
        padding: 0px 24px 10px 24px;
    }
    @media screen and (max-width: $break-tablet) {
        padding: 0px;
    }
}

@-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(500px);
              transform: translateX(500px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(500px);
              transform: translateX(500px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
