$break-small: 575px;
$break-tablet: 769px;

.helpDiv {
    position: fixed;
    z-index: 15;
    padding: 40px 40px;
    width: auto;
    background-color:white;
    border-radius: 4px;
    right: 24px;
    margin-top: 64px;
    text-align: center;
    -moz-box-shadow: 0px 7px 21px rgba(44,62,71,0.3);
	-webkit-box-shadow: 0px 7px 21px rgba(44,62,71,0.3);
    box-shadow: 0px 7px 21px rgba(44,62,71,0.3);
    @media screen and (max-width: $break-tablet) {
        margin-top: 64px;
    }
    @media screen and (max-width: $break-small) {
        margin-top: 64px;
        -moz-box-shadow: 0px 7px 21px rgba(44,62,71,0.5);
	    -webkit-box-shadow: 0px 7px 21px rgba(44,62,71,0.5);
        box-shadow: 0px 7px 21px rgba(44,62,71,0.5);
    }
}

.helpDiv:after {
	bottom: 100%;
	left: 94%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.helpDiv:after {
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: #FFFF;
	border-width: 10px;
    margin-left: -20px;
    @media screen and (max-width: $break-small) {
        margin-left: -12px;
    }
}
.helpDiv:before {
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: #ffff;
	border-width: 36px;
	margin-left: -36px;
}

.helpText {
    position: relative;
    top: -8px;
    font-size: 16px;
    font-family: lato;
    font-weight: 500;
    color: #404348;
    line-height: 24px;
}

.helpTitle {
    position: relative;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 800;
    font-family: 'Catamaran', sans-serif;
}

.helpPhoto {
    position: relative;
    border-radius: 50%;
    width: 48px;
    margin: 16px 0 20px;
}

.helpPhone {
    font-size: 16px;
    font-family: lato;
    color: #404348;
    font-weight: 500;
}

.helpMail {
    font-size: 16px;
    font-family: lato;
    color: #404348;
    font-weight: 500;
}

.subText {
    font-size: 14px;
    color: #898f98;
    font-family: lato;
    font-weight: 500;
}

.bellenIcon {
    height: 22px;
}

.mailenIcon {
    height: 20px;
    margin-right: 4px;
}